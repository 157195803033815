<template>
  <div class="businesess-table-wrapper">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="items.length"
      hide-default-footer
    >
      <v-progress-linear
          slot="progress"
          color="primary"
          indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.month">
            <td>
                {{ item.month }}
            </td>
            <td>
              {{ formatPrice(item.paid_by_brands) }}
            </td>
            <td>
              {{ formatPrice(item.not_paid_by_brands) }}
            </td>
            <td>
              {{ formatPrice(item.paid_service_fee_by_brands) }}
            </td>
            <td>
              {{ formatPrice(item.not_paid_service_fee_by_brands) }}
            </td>
            <td>
              {{ formatPrice(item.paid_to_private_influencers) }}
            </td>
            <td>
              {{ formatPrice(item.paid_to_business_influencers) }}
            </td>
            <td>
              {{ formatPrice(item.not_paid_to_private_influencers) }}
            </td>
            <td>
              {{ formatPrice(item.not_paid_to_business_influencers) }}
            </td>
            <td>
              {{ formatPrice(item.legacy_paid_to_private_influencers) }}
            </td>
            <td>
              {{ formatPrice(item.legacy_paid_to_business_influencers) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { text: "Month", value: "month_name", sortable: false },
      { text: "Paid by brands", value: "month_name", sortable: false },
      { text: "Not paid by brands", value: "month_name", sortable: false },
      { text: "Paid service fee by brands", value: "month_name", sortable: false },
      { text: "Not paid service fee by brands", value: "month_name", sortable: false },
      { text: "Paid to private influencers", value: "month_name", sortable: false },
      { text: "Paid to business influencers", value: "month_name", sortable: false },
      { text: "Not paid to private influencers", value: "month_name", sortable: false },
      { text: "Not paid to business influencers", value: "month_name", sortable: false },
      { text: "Legacy paid to private influencers", value: "month_name", sortable: false },
      { text: "Legacy paid to business influencers", value: "month_name", sortable: false },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  }
}
</script>