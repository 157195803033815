<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Monthly Overview</div>
        <div class="subtitle-1 light-grey">
          This is not booking numbers.
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-select
          x-small
          outlined
          color="primary"
          class="mb-3"
          label="Year"
          v-model="selectYear"
          :items="years"
          item-value="value"
          item-text="label"
          return-object
          @input="loadViewData"
      >
      </v-select>
      <monthly-finance-overview-data-table
          :items="items"
          :loading="loading"
      ></monthly-finance-overview-data-table>

    </v-card-text>
  </v-card>
</template>

<script>
import MonthlyFinanceOverviewDataTable from "../data-tables/MonthlyFinanceOverviewDataTable.vue";
import {mapActions} from "vuex";

export default {
  components: {
    MonthlyFinanceOverviewDataTable
  },
  data: () => ({
    items: [],
    loading: true,
    selectYear: {
      value: "2024_current",
      label: "2024 - current owner",
    },
    years: [{
        value: "2019",
        label: "2019",
    },{
      value: "2020",
      label: "2020",
    },{
      value: "2021",
      label: "2021",
    },{
      value: "2022",
      label: "2022",
    },{
      value: "2023",
      label: "2023",
    },{
      value: "2024_previous",
      label: "2024 - previous owner",
    },{
      value: "2024_current",
      label: "2024 - current owner",
    },{
      value: "2025",
      label: "2025",
    }]
  }),
  methods: {
    ...mapActions("moderator", [
      "getMonthlyFinanceOverview",
    ]),
    loadViewData() {
      this.loading = true;
      this.items = [];
      let filters = {
        year: this.selectYear.value,
      };

      this.getMonthlyFinanceOverview({...filters} ).then(
          (response) => {
            this.items = response.monthlyFinanceOverview.data;
            this.loading = false;
          }
      );

    },
  },
  created() {
    this.loadViewData();
  },
}
</script>

<style lang="scss">
div.transfer-widgets {
  .v-application {
    div.display-1 {
      font-size: 1.7rem !important;
    }
  }
}
</style>
